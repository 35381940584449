body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  color:#eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.item {
  padding: 0 1rem;
  margin: 0.5rem;
}

.item .hover {
  max-height: 0;
  overflow-y: hidden;
  transition: all 1s;
  padding:0 1rem;
}

.item.open .hover {
  max-height: 80rem;
}

.item .hover pre {
  max-width: 100%;
  white-space: pre-line;
  font-family: inherit;
  margin: 0.5rem 0;
}

.item.exotic > .title {
  background: rgb(170,11,11);
  background: linear-gradient(90deg, rgba(170,11,11,1) 0%, rgba(170,11,11,1) 5%, rgba(117,13,13,1) 5%, rgba(0,0,0,1) 100%);
}

.item.highend > .title {
  background: rgb(217,129,15);
  background: linear-gradient(90deg, rgba(217,129,15,1) 0%, rgba(217,129,15,1) 5%, rgba(117,77,13,1) 5%, rgba(0,0,0,1) 100%);
}

.item > .title {
  position: relative;
  text-align: left;
  padding-left: calc(5% + 1rem);
  padding-top:0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.item > .title span.arrow {
  display:none;
}

.item > .title:hover span.arrow {
  display:block;
  position: absolute;
  left:50%;
  bottom:0.5rem;
  font-size: 4rem;
}

.item > .title p {
  margin: 0.5rem 0;
}

.item > .title button {
  background-color: #222;
  border-radius: 50%;
  color: #ccc;
  font-weight: bold;
  cursor: pointer;
  border: none;
  position: absolute;
  top:50%;
  right:1rem;
  height:4rem;
  width: 4rem;
  transform: translateY(-50%);
  transition: all 0.5s;
}

.item > .title button > span {
  font-size: 2.5rem;
}

.item > .title button.owned:hover {
  background-color: red;
}

.item > .title button.needed:hover {
  background-color: green;
}

.item.exotic > .title .rarity {
  color:red;
}

.item > .title .rarity, .item > .title .type {
  font-weight: bold;
}

h2 {
  text-align: center;
  font-size: 1.25rem;
}

.columns {
  display:grid;
  grid-template-columns: 6fr 6fr;
}

.visible-xs {
  display: none;
}
.tab-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
}
button.tab {
  margin: 0.5rem;
  background: none;
  border: 1px solid #ccc;
  color: white;
  font-size: 1.25rem;
  padding: 0.5rem;
  font-weight: bold;
  cursor: pointer;
}

button.tab.active {
  background: #222;
}

@media only screen and (max-width: 600px) {
  .columns {
    display:grid;
    grid-template-columns: 6fr;
  }
  .hidden-xs {
    display:none;
  }
  .visible-xs {
    display: block;
  }
}